/*********************************************************************************
 * Font Imports
 ********************************************************************************/
@font-face {
  font-family: Flow;
  src: url(fonts/flow-circular.woff);
  font-display: swap;
}
/*********************************************************************************
 * Helpers for style guide
 ********************************************************************************/
.sg-font-wireframe {
  font-family: Flow !important;
  font-size: 30px !important;
  font-weight: normal !important;
  line-height: 20px !important;
}

/*********************************************************************************
 * Variables - Colors, Fonts, etc.
 ********************************************************************************/
/*********************************************************************************
 * Global Resets
 ********************************************************************************/
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (min-width: 1024px) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #666 transparent;
  }
  *::-webkit-scrollbar {
    height: 1rem;
    width: 1rem;
  }
  *::-webkit-scrollbar-corner, *::-webkit-scrollbar-track {
    background: inherit;
  }
  *::-webkit-scrollbar-thumb {
    background: rgba(102, 102, 102, 0.5);
    border-radius: 0.5rem;
    border: 0.25rem solid transparent;
    background-clip: content-box;
  }
  *::-webkit-scrollbar-thumb:hover {
    background: rgba(102, 102, 102, 0.8);
    background-clip: content-box;
  }
}

::selection {
  background-color: #f16581;
  color: #fff;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  text-rendering: optimizeLegibility;
}

/*********************************************************************************
 * Wrap all of this builder's base HTML styling inside a .kss-style selector.
 ********************************************************************************/
.kss-style {
  color: #666;
  font-family: "Mulish", "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.kss-style a {
  color: #0f7ae6;
  text-decoration: none;
  transition-property: color;
  transition-duration: 0.15s;
  outline: 0;
}
.kss-style a:focus {
  border-bottom: 1px dashed #0f7ae6;
}
.kss-style a:visited {
  color: #0f7ae6;
}
.kss-style a:hover, .kss-style a:focus {
  color: #66adf5;
}
.kss-style a:active {
  color: #fa8005;
}
.kss-style a:hover, .kss-style a:active {
  outline: 0;
}
.kss-style a[href^=http]::after {
  content: "";
  display: inline-block;
  mask: url(images/icons/external-link-solid.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 1rem 1rem;
  background-color: #ccc;
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.125rem;
}
.kss-style p {
  margin: 0 0 1rem;
}
.kss-style h1,
.kss-style h2,
.kss-style h3,
.kss-style h4,
.kss-style h5,
.kss-style h6 {
  margin-bottom: 1rem;
  font-family: "Mulish", "Helvetica Neue", Arial, sans-serif;
  color: #111;
  line-height: 1.25em;
  font-weight: 400;
}
.kss-style h4,
.kss-style h5,
.kss-style h6 {
  font-weight: 700;
}
.kss-style h4 {
  color: #fb9937;
}
.kss-style h1 {
  font-size: 45.232px;
  line-height: 3.5rem;
  margin-top: 0;
}
.kss-style h2 {
  font-size: 31.984px;
  line-height: 2.5rem;
  margin-top: 0;
}
.kss-style h3 {
  font-size: 22.624px;
  line-height: 2rem;
  margin-top: 0;
}
.kss-style h4 {
  font-size: 16px;
  line-height: 1.5rem;
  margin-top: 0;
}
.kss-style h5 {
  font-size: 11.312px;
  line-height: 1rem;
  margin-top: 0;
}
.kss-style h6 {
  font-size: 8px;
  line-height: 1rem;
  margin-top: 0;
}
.kss-style blockquote {
  color: #888;
  margin: 0 0 1rem 0;
  padding: 0.5rem 2rem 0.5rem 1.75rem;
  border-left: 0.25em solid #eee;
}
.kss-style blockquote p:last-child {
  margin-bottom: 0;
}
.kss-style hr {
  border: 0;
  border-top: 1px solid #ddd;
  margin: 0 0 1rem;
  padding: 0;
}
.kss-style em,
.kss-style i {
  opacity: 0.8;
}
.kss-style pre,
.kss-style code,
.kss-style kbd,
.kss-style samp {
  font-family: "Roboto Mono", "Lucida Console", "Courier New", Courier, monospace;
  color: crimson;
  font-size: max(0.875em, 14px);
}
.kss-style kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #111;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 700;
  line-height: 1;
  padding: 0.125rem 0.25rem;
  white-space: nowrap;
}
.kss-style pre {
  margin: 0 0 1rem;
  white-space: pre;
  overflow: auto;
}
.kss-style ins {
  color: #111;
  background: #ff9;
  text-decoration: none;
}
.kss-style mark {
  color: #111;
  background: #ff0;
  font-weight: bold;
}
.kss-style sub,
.kss-style sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
.kss-style sup {
  top: -0.5em;
}
.kss-style sub {
  bottom: -0.25em;
}
.kss-style ul,
.kss-style ol {
  margin: 0 0 1rem;
  padding: 0 0 0 2rem;
}
.kss-style li p:last-child {
  margin: 0;
}
.kss-style dd {
  margin: 0 0 0 2rem;
}
.kss-style figure {
  margin: 0 0 1rem;
  padding: 1rem 1rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(251, 153, 55, 0.5);
}
.kss-style figcaption {
  color: #888;
  font-size: 0.75rem;
  padding-top: 0.25rem;
}
.kss-style img {
  max-width: 100%;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}
.kss-style table {
  border-collapse: collapse;
  border-spacing: 0;
}
.kss-style td {
  vertical-align: top;
}
@media print {
  .kss-style a,
.kss-style a:visited {
    text-decoration: underline;
  }
  .kss-style hr {
    height: 1px;
    border: 0;
    border-bottom: 1px solid black;
  }
  .kss-style a[href]:after {
    content: " (" attr(href) ")";
  }
  .kss-style a[href^="javascript:"]:after,
.kss-style a[href^="#"]:after {
    content: "";
  }
  .kss-style abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .kss-style pre,
.kss-style blockquote {
    border: 1px solid #999;
    padding-right: 1em;
    page-break-inside: avoid;
  }
  .kss-style tr,
.kss-style img {
    page-break-inside: avoid;
  }
  .kss-style img {
    max-width: 100% !important;
  }
  .kss-style p,
.kss-style h2,
.kss-style h3 {
    orphans: 3;
    widows: 3;
  }
  .kss-style h2,
.kss-style h3 {
    page-break-after: avoid;
  }
}

/*********************************************************************************
 * Layout and page background
 ********************************************************************************/
#kss-node {
  display: flex;
  flex-direction: column;
  background: #fff;
}
@media screen and (min-width: 1024px) {
  #kss-node {
    flex-direction: row;
  }
}
#kss-node.kss-fullscreen-mode .kss-sidebar,
#kss-node.kss-fullscreen-mode .kss-section:not(.is-fullscreen) {
  display: none;
}
#kss-node .kss-main {
  padding: 0 1rem;
  background: #fff;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-main {
    padding: 0 2rem;
    flex: 3;
    max-width: 50rem;
    min-width: 43.75rem;
  }
}
#kss-node .kss-sidebar {
  position: sticky;
  top: 0;
  z-index: 10;
  background: linear-gradient(120deg, #fb9937 30%, rgba(255, 187, 15, 0.6));
  color: white;
  backdrop-filter: blur(8px);
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
    overflow: hidden;
    padding: 0;
    border-bottom: 0;
    background: linear-gradient(165deg, #fb9937, #ffbb0f);
    box-shadow: inset -0.5rem 0 0.75rem -0.65rem rgba(0, 0, 0, 0.5);
    min-width: 18.75rem;
    max-width: 18.75rem;
    backdrop-filter: none;
  }
}
@media screen and (min-width: 1024px) {
  #kss-node.centered-layout {
    justify-content: center;
    background-image: linear-gradient(165deg, #fb9937, #ffbb0f 65%);
    background-position: left top;
    background-size: 50vw 100vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  #kss-node.centered-layout .kss-sidebar {
    background: none;
  }
}

/*********************************************************************************
 * Sidebar-area components
 ********************************************************************************/
#kss-node .kss-doc-title {
  margin: 0;
  color: white;
  font-size: 22.624px;
  line-height: 2rem;
  transition: color 0.15s;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-doc-title {
    padding-right: 1rem;
  }
}
#kss-node .kss-doc-title a {
  color: white;
}
#kss-node .kss-doc-title a:hover, #kss-node .kss-doc-title a:focus, #kss-node .kss-doc-title a:active {
  color: rgba(255, 255, 255, 0.8);
}
#kss-node .kss-doc-title a:focus {
  border-bottom: 0;
}
#kss-node .kss-doc-version {
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  white-space: nowrap;
  line-height: 2rem;
}
#kss-node .kss-doc-version__value {
  font-family: "Roboto Mono", "Lucida Console", "Courier New", Courier, monospace;
}
#kss-node .kss-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: baseline;
  column-gap: 1rem;
  margin-bottom: 0.5rem;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-header {
    padding: 2rem 1rem 0 2rem;
    margin-bottom: 1rem;
    flex-direction: column;
  }
}
#kss-node .kss-nav {
  user-select: none;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-nav {
    display: flex;
    margin-bottom: 1rem;
    padding: 0 1rem 0 0;
    padding-bottom: 0.5rem;
    overflow: auto;
    height: 100%;
    overscroll-behavior: contain;
  }
}
#kss-node .kss-nav__menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 0.25rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-nav__menu {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0;
  }
}
#kss-node .kss-nav__menu-item {
  display: inline-block;
  padding-right: 24px;
}
#kss-node .kss-nav__menu-item .subtitle {
  display: none;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-nav__menu-item {
    display: list-item;
    padding-right: 0;
    padding-left: 2rem;
  }
  #kss-node .kss-nav__menu-item .subtitle {
    display: inline;
    color: rgba(255, 255, 255, 0.5);
  }
}
#kss-node .kss-nav__menu-link {
  position: relative;
  display: inline-flex;
  gap: 0.5rem;
  color: white;
  outline: 0;
  line-height: 1.25;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-nav__menu-link {
    display: flex;
    line-height: 1.5;
  }
}
#kss-node .kss-nav__menu-link code {
  color: inherit;
}
#kss-node .kss-nav__menu-link:hover, #kss-node .kss-nav__menu-link:active, #kss-node .kss-nav__menu-link:focus {
  color: rgba(255, 255, 255, 0.8);
  border: 0;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-nav__menu-link:before {
    content: " ";
    position: absolute;
    left: -2rem;
    width: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
  }
}
#kss-node .kss-nav__menu-link.is-in-viewport:before {
  background-color: rgba(255, 255, 255, 0.8);
  width: 0.25rem;
  transition: background-color 0.3s;
  border-radius: 0.125rem;
  max-height: 1.5rem;
}
#kss-node .kss-nav__menu-link .kss-nav__name {
  font-weight: 300;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-nav__menu-link .kss-nav__name {
    flex: 1;
  }
}
#kss-node .kss-nav__menu-link .kss-nav__name.bold {
  font-weight: 700;
}
#kss-node .kss-nav__menu-link:focus > .kss-nav__name {
  text-decoration: underline;
}
#kss-node .kss-nav__menu-child {
  display: none;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-nav__menu-child {
    position: relative;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0.5rem 0;
    padding: 0;
    min-height: 2.5rem;
    overflow-y: auto;
    border-style: solid;
    border-width: 1px 0;
    border-image: linear-gradient(to right, transparent, transparent 2rem, rgba(255, 255, 255, 0.5) 2rem, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1)) 1;
    mask-image: linear-gradient(black, transparent 2px, transparent 0.3333333333rem, black 1rem, black calc(100% - 1rem), transparent calc(100% - 0.3333333333rem), transparent calc(100% - 2px), black);
  }
  #kss-node .kss-nav__menu-child li:first-child {
    padding-top: 0.5rem;
  }
  #kss-node .kss-nav__menu-child li:last-child {
    padding-bottom: 0.5rem;
  }
}
#kss-node .kss-nav__ref {
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
}
#kss-node .kss-nav__ref-child {
  font-weight: 300;
}
#kss-node .kss-nav__weight {
  white-space: nowrap;
  margin-left: 0.125rem;
  background: black;
  color: white;
  opacity: 0.1;
  font-size: 0.625rem;
  padding: 0.125rem 0.25rem;
  font-weight: 400;
  vertical-align: middle;
  border-radius: 0.125rem;
  letter-spacing: 0.5px;
  transition: opacity 0.15s;
}
#kss-node .kss-nav__weight::after {
  content: "g";
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-nav__weight {
    position: absolute;
    right: 0;
    bottom: 0.25rem;
    line-height: 1;
  }
}
#kss-node .kss-nav__name:hover .kss-nav__weight {
  opacity: 1;
}

/*********************************************************************************
 * Content-area components
 ********************************************************************************/
#kss-node .hljs {
  color: #e0e2e4;
  background: #282b2e;
}
#kss-node .hljs-emphasis,
#kss-node .hljs-keyword,
#kss-node .hljs-selector-tag,
#kss-node .hljs-literal,
#kss-node .hljs-selector-id {
  color: #c792ea;
}
#kss-node .hljs-number {
  color: #fffa9e;
}
#kss-node .hljs-attribute,
#kss-node .hljs-regexp,
#kss-node .hljs-link {
  color: #00d9ff;
}
#kss-node .hljs-meta {
  color: #557182;
}
#kss-node .hljs-tag {
  color: #e0e2e4;
}
#kss-node .hljs-name,
#kss-node .hljs-bullet,
#kss-node .hljs-subst,
#kss-node .hljs-type,
#kss-node .hljs-built_in,
#kss-node .hljs-selector-attr,
#kss-node .hljs-selector-pseudo,
#kss-node .hljs-addition,
#kss-node .hljs-variable,
#kss-node .hljs-template-tag,
#kss-node .hljs-template-variable {
  color: #ff4579;
}
#kss-node .hljs-strong,
#kss-node .hljs-attr {
  color: #ffbf7a;
}
#kss-node .hljs-string,
#kss-node .hljs-symbol {
  color: #6af699;
}
#kss-node .hljs-comment,
#kss-node .hljs-quote,
#kss-node .hljs-deletion {
  color: #818e96;
}
#kss-node .hljs-selector-class {
  color: #ffbf7a;
}
#kss-node .hljs-keyword,
#kss-node .hljs-selector-tag,
#kss-node .hljs-literal,
#kss-node .hljs-doctag,
#kss-node .hljs-title,
#kss-node .hljs-section,
#kss-node .hljs-type,
#kss-node .hljs-name,
#kss-node .hljs-strong {
  font-weight: bold;
}
#kss-node .hljs-code,
#kss-node .hljs-title.class_,
#kss-node .hljs-class .hljs-title,
#kss-node .hljs-section {
  color: white;
}
#kss-node .hljs {
  padding: 1rem;
  border-radius: 0.25rem;
}
#kss-node .kss-section {
  position: relative;
  border-bottom: 1px solid #ddd;
  padding: 2rem 0;
  background: #fff;
}
#kss-node .kss-section--depth-0 {
  max-width: 50rem;
  padding-top: 1.5rem;
}
#kss-node .kss-section--depth-0 h1:not(:first-child) {
  padding-top: 1.5rem;
}
#kss-node .kss-section--depth-0 :where(h2):not(:first-child) {
  padding-top: 1.5rem;
}
#kss-node .kss-section--depth-0 :where(h3):not(:first-child) {
  padding-top: 0.5rem;
}
#kss-node .kss-section.is-fullscreen {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  box-sizing: border-box !important;
  object-fit: contain !important;
  transform: none !important;
  overflow-x: hidden;
  padding: 2rem;
}
#kss-node .kss-title {
  color: #111;
}
#kss-node .kss-title code {
  font-weight: normal;
  color: inherit;
}
#kss-node .kss-title__ref {
  font-size: 16px;
  line-height: 16px;
  color: #666;
}
#kss-node .kss-title__ref:before {
  content: "Section ";
}
#kss-node .kss-title__permalink {
  color: #000;
  text-decoration: none;
  pointer-events: none;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-title__permalink {
    pointer-events: all;
  }
}
#kss-node .kss-title__permalink:hover, #kss-node .kss-title__permalink:focus, #kss-node .kss-title__permalink:active {
  color: #0f7ae6;
  border: 0;
  outline: 0;
}
@media screen and (min-width: 607px) {
  #kss-node .kss-title__permalink:hover .kss-title__permalink-hash, #kss-node .kss-title__permalink:focus .kss-title__permalink-hash, #kss-node .kss-title__permalink:active .kss-title__permalink-hash {
    display: inline;
  }
}
#kss-node .kss-title__permalink:focus > .kss-title__ref {
  border-bottom: 1px dashed #666;
}
#kss-node .kss-title__permalink-hash {
  display: none;
  color: #ccc;
}
#kss-node .kss-toolbar {
  position: absolute;
  top: 2rem;
  right: 0;
  display: inline-block;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  line-height: 1;
  padding: 0.5rem;
  transition: background-color 0.15s;
}
#kss-node .kss-toolbar:hover {
  background-color: #eee;
}
#kss-node .kss-toolbar a {
  box-sizing: content-box;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;
  vertical-align: top;
  outline: 0;
}
#kss-node .kss-toolbar a + a {
  margin-left: 6px;
}
#kss-node .kss-toolbar a .kss-toolbar__icon-fill {
  fill: #ccc;
}
#kss-node .kss-toolbar a svg.on {
  display: none;
}
#kss-node .kss-toolbar a:focus, #kss-node .kss-toolbar a:hover {
  border-color: #000;
}
#kss-node .kss-toolbar a:focus .kss-toolbar__icon-fill, #kss-node .kss-toolbar a:hover .kss-toolbar__icon-fill {
  fill: #000;
}
#kss-node .kss-toolbar a:focus {
  border: 0;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1), 0 0 0 0.25rem rgba(0, 0, 0, 0.1);
}
#kss-node .is-fullscreen .kss-toolbar {
  right: 2rem;
}
#kss-node .is-fullscreen .kss-toolbar a[data-kss-fullscreen], #kss-node.kss-grid-mode .kss-toolbar a[data-kss-grid], #kss-node.kss-markup-mode .kss-toolbar a[data-kss-markup] {
  background-color: #666;
  border-radius: 0.25rem;
}
#kss-node .is-fullscreen .kss-toolbar a[data-kss-fullscreen] .kss-toolbar__icon-fill, #kss-node.kss-grid-mode .kss-toolbar a[data-kss-grid] .kss-toolbar__icon-fill, #kss-node.kss-markup-mode .kss-toolbar a[data-kss-markup] .kss-toolbar__icon-fill {
  fill: #fff;
}
#kss-node .is-fullscreen .kss-toolbar a[data-kss-fullscreen] svg.on, #kss-node.kss-grid-mode .kss-toolbar a[data-kss-grid] svg.on, #kss-node.kss-markup-mode .kss-toolbar a[data-kss-markup] svg.on {
  display: block;
}
#kss-node .is-fullscreen .kss-toolbar a[data-kss-fullscreen] svg.off, #kss-node.kss-grid-mode .kss-toolbar a[data-kss-grid] svg.off, #kss-node.kss-markup-mode .kss-toolbar a[data-kss-markup] svg.off {
  display: none;
}
#kss-node .kss-description {
  max-width: 50rem;
}
#kss-node .kss-description img {
  border-radius: 0.25rem;
  opacity: 0.5;
  transition: opacity 0.15s;
}
#kss-node .kss-description img:hover {
  opacity: 0.9;
}
#kss-node .kss-description code {
  word-break: break-word;
}
#kss-node .lss-legend__wrapper,
#kss-node .kss-parameters__wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: #ddd;
  margin-bottom: 1rem;
  max-height: 480px;
  overflow-y: auto;
}
#kss-node .kss-parameters__wrapper {
  background: #282b2e;
}
#kss-node .lss-legend,
#kss-node .kss-parameters {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
  width: 100%;
  margin-bottom: 0;
}
@media screen and (min-width: 1024px) {
  #kss-node .lss-legend,
#kss-node .kss-parameters {
    display: table;
  }
}
#kss-node .lss-legend__title,
#kss-node .kss-parameters__title {
  padding: 0.5rem 1rem;
  font-weight: bold;
  background-color: inherit;
  position: sticky;
  top: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
#kss-node .kss-parameters__title {
  color: #eee;
}
#kss-node .lss-legend__item,
#kss-node .kss-parameters__item {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1024px) {
  #kss-node .lss-legend__item,
#kss-node .kss-parameters__item {
    display: table-row;
  }
}
#kss-node .lss-legend__item:nth-child(odd),
#kss-node .kss-parameters__item:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.95);
}
#kss-node .lss-legend__item:nth-child(even),
#kss-node .kss-parameters__item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.5);
}
#kss-node .kss-parameters__item:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.1);
}
#kss-node .kss-parameters__item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
}
#kss-node .lss-legend__name,
#kss-node .kss-parameters__name {
  display: table-cell;
  padding: 0 1rem;
  white-space: nowrap;
  width: 1rem;
}
#kss-node .kss-parameters__name code {
  color: #ef4e6e;
}
#kss-node .lss-legend__description,
#kss-node .kss-parameters__description {
  display: table-cell;
  color: #8c8c8c;
  padding: 0 1rem 0.25rem;
}
#kss-node .lss-legend__description code,
#kss-node .kss-parameters__description code {
  color: #f16581;
}
@media screen and (min-width: 1024px) {
  #kss-node .lss-legend__description,
#kss-node .kss-parameters__description {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
#kss-node .kss-parameters__default-value code {
  white-space: nowrap;
}
#kss-node .kss-modifier__wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem;
}
#kss-node .kss-modifier-examples__wrapper {
  padding: 1rem 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 0.25rem;
  overflow-x: auto;
}
#kss-node .kss-modifier__heading {
  padding: 1rem 0;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  user-select: none;
  display: flex;
  gap: 1rem;
  align-items: center;
}
#kss-node .kss-modifier__heading::after {
  content: "";
  display: block;
  border-top: 1px dotted #cccccc;
  height: 1px;
  width: 100%;
}
#kss-node .kss-modifier__default-name {
  font-weight: bold;
  padding: 0 1rem;
}
#kss-node .kss-modifier__item {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 1rem 1rem 0;
}
#kss-node .kss-modifier__item:first-child {
  padding-top: 0;
}
@media screen and (min-width: 1024px) {
  #kss-node .kss-modifier__item {
    flex-direction: row;
    gap: 1rem;
  }
}
#kss-node .kss-modifier__name {
  font-weight: bold;
  white-space: nowrap;
  min-width: 10rem;
}
#kss-node .kss-modifier__description {
  max-width: 50rem;
}
#kss-node .kss-modifier__example {
  padding: 1.5rem;
  position: relative;
  z-index: 0;
  width: fit-content;
  min-width: 100%;
}
#kss-node .kss-modifier__example:last-child {
  margin-bottom: 0;
}
#kss-node .kss-modifier__example:not(:last-child) {
  border-bottom: 1px dashed #eee;
}
#kss-node.kss-grid-mode .kss-modifier-examples__wrapper .kss-modifier__example::before {
  --grid-size: 8px;
  --baseline-factor: 3;
  --grid-color: rgba(0, 128, 0, 0.2);
  --grid-color-dark: rgba(0, 128, 0, 0.4);
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background: linear-gradient(var(--grid-color-dark) 1px, transparent 1px, transparent calc(var(--grid-size) - 1px), var(--grid-color) var(--grid-size), transparent calc(var(--grid-size) + 1px), transparent calc(var(--grid-size) * (var(--baseline-factor) - 1) - 1px), var(--grid-color) calc(var(--grid-size) * (var(--baseline-factor) - 1)), transparent calc(var(--grid-size) * (var(--baseline-factor) - 1) + 1px)), linear-gradient(to right, var(--grid-color) 1px, transparent 1px);
  background-size: var(--grid-size) calc(var(--grid-size) * var(--baseline-factor));
  background-repeat: repeat;
  mix-blend-mode: difference;
  pointer-events: none;
}
#kss-node .kss-modifier__example-footer {
  clear: both;
}
#kss-node .kss-markup {
  margin: 0 0 1rem;
}
#kss-node .kss-markup[open] summary::before {
  content: "";
  mask: url(images/icons/chevron-down-solid.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #666;
  width: 20px;
  height: 20px;
}
#kss-node .kss-markup summary {
  padding: 1rem 0;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  cursor: pointer;
  user-select: none;
  outline: 0;
  display: flex;
  gap: 0;
  align-items: center;
}
#kss-node .kss-markup summary::before {
  content: "";
  mask: url(images/icons/chevron-right-solid.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #666;
  width: 20px;
  height: 20px;
  margin-right: 0.25rem;
}
#kss-node .kss-markup summary::after {
  content: "";
  display: block;
  border-top: 1px dotted #cccccc;
  height: 1px;
  width: 100%;
  margin-left: 1rem;
}
#kss-node .kss-markup summary span {
  border-bottom: 1px dashed transparent;
}
#kss-node .kss-markup summary:focus > span {
  border-color: #666;
}
#kss-node .kss-markup pre {
  margin: 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
#kss-node .kss-colors {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
}
@media screen and (min-width: 600px) {
  #kss-node .kss-colors {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  #kss-node .kss-colors {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1600px) {
  #kss-node .kss-colors {
    grid-template-columns: repeat(4, 1fr);
  }
}
#kss-node .kss-colors .kss-color {
  height: 100%;
}
#kss-node .kss-color {
  background: #fff;
  border-radius: 0.25rem;
  margin: 0;
  padding: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: box-shadow 0.15s;
}
#kss-node .kss-color:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
#kss-node .kss-color-figure {
  border-radius: 0.25rem 0.25rem 0 0;
  height: 3rem;
  font-size: 0;
}
#kss-node .kss-color-caption {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
}
#kss-node .kss-color-name {
  font-weight: bold;
  color: #666;
}
#kss-node .kss-color-code {
  color: #888;
}
#kss-node .kss-color-desc {
  font-size: 0.875em;
}
#kss-node .kss-source {
  font-size: 0.875rem;
  color: #ccc;
}
#kss-node .kss-source code {
  color: gray;
}
#kss-node pre.hljs {
  line-height: 1.5em;
}
#kss-node pre.hljs code {
  color: inherit;
}
#kss-node pre.hljs .bg-highlight {
  position: relative;
  padding: 0 0.25rem;
}
#kss-node pre.hljs .bg-highlight::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 0.25rem;
  background-color: currentColor;
  opacity: 0.25;
  pointer-events: none;
}
#kss-node details pre.hljs {
  max-height: 240px;
}
#kss-node :not(.kss-markup) > pre.hljs {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

/*********************************************************************************
 * Style guide custom elements
 ********************************************************************************/
.kss-style [class^=tag--] {
  display: inline-block;
  background: #666;
  color: white;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  font-size: 11.312px;
  font-weight: 700;
  font-family: "Roboto Mono", "Lucida Console", "Courier New", Courier, monospace;
  line-height: 1.75;
  white-space: nowrap;
}
.kss-style [class^=tag--]::after {
  content: "";
}
.kss-style .tag--kss {
  background: #6e13ae;
}
.kss-style .tag--kss::after {
  content: "kss-node";
}
.kss-style .tag--lss {
  background: #fb9937;
}
.kss-style .tag--lss::after {
  content: "living-styleguide-skeleton";
}
.kss-style .delimiter {
  display: flex;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 900;
  letter-spacing: 1px;
  color: #ffbb0f;
  user-select: none;
  margin: 1.5rem 0;
}
.kss-style .delimiter::before, .kss-style .delimiter::after {
  content: "";
  display: block;
  border-top: 1px dotted currentColor;
  width: 100%;
}
.kss-style .delimiter::before {
  max-width: 1rem;
}
.kss-style .delimiter-1 {
  color: #ccc;
}
@media screen and (max-width: 1023px) {
  .kss-style .hide-mobile {
    display: none;
  }
}

/*********************************************************************************
 * Custom behaviors that depend on JS-attached selectors
 ********************************************************************************/
#kss-node.js_backdrop-open {
  overflow: hidden;
}

#kss-node .kss-sidebar {
  transition: transform 0.15s ease-out;
}
#kss-node.js_scroll-up .kss-sidebar {
  transform: translateY(0);
}
#kss-node.js_scroll-down .kss-sidebar {
  transform: translateY(-100%);
}
@media screen and (min-width: 1024px) {
  #kss-node.js_scroll-up .kss-sidebar {
    transform: unset;
  }
  #kss-node.js_scroll-down .kss-sidebar {
    transform: unset;
  }
}

#kss-node .has-copy-button {
  position: relative;
}
#kss-node .has-copy-button [data-clipboard-snippet] {
  outline: 0;
}
#kss-node .has-copy-button [data-clipboard-snippet]:focus {
  opacity: 1;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
#kss-node .has-copy-button > [data-clipboard-snippet] {
  position: sticky;
  float: right;
  top: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  background-color: #ddd;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.25rem;
  transition: all 0.15s;
}
#kss-node .has-copy-button > [data-clipboard-snippet]:hover {
  background-color: #f9f9f9;
}
#kss-node .has-copy-button > [data-clipboard-snippet]::before {
  content: "";
  display: block;
  background: url("images/icons/clipboard-copy.svg");
  height: 1rem;
  width: 1rem;
}
#kss-node .has-copy-button:hover > [data-clipboard-snippet] {
  opacity: 1;
  pointer-events: all;
}
#kss-node [data-clipboard-copy] {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.15s;
}
#kss-node [data-clipboard-copy]:hover {
  opacity: 0.8;
}

/*********************************************************************************
 * Vendor styles customizations
 ********************************************************************************/
/* Tippy.js */
[data-tippy-root] {
  z-index: 10 !important;
}

.tippy-box {
  font-family: "Mulish", "Helvetica Neue", Arial, sans-serif;
  font-size: 11.312px;
  font-weight: bold;
  background-color: #111111;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.3);
}

.tippy-arrow {
  color: #111111;
}
.tippy-arrow::before {
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.3));
}

.tippy-box[data-placement^=top] .tippy-arrow::before {
  bottom: -8px;
}
.tippy-box[data-placement^=bottom] .tippy-arrow::before {
  top: -8px;
}

/*********************************************************************************
 * Vue components
 ********************************************************************************/
.kss-style .backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(251, 153, 55, 0.65);
  /* Old values below: 
   * Disabled because major performance hit on less capable machines resulting
   * in sluggish animations.
   */
}
.kss-style .modal {
  position: relative;
  margin: 5vh auto auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: min(600px, 80vw);
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}
@media screen and (min-width: 1024px) {
  .kss-style .modal {
    margin-top: 10vh;
  }
}
.kss-style .modal svg {
  fill: inherit;
  stroke: inherit;
}
.kss-style .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #fb9937;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  box-shadow: 0 -1px 1px 0 #fb9937;
}
.kss-style .modal__header-title {
  font-size: 22.624px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  line-height: 1;
  color: white;
  user-select: none;
}
.kss-style .modal__header-title .subtitle {
  display: none;
}
@media screen and (min-width: 1024px) {
  .kss-style .modal__header-title .subtitle {
    display: inline;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
  }
}
.kss-style .modal__header-close {
  border: 0;
  cursor: pointer;
  background: transparent;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  opacity: 0.5;
  transition: opacity 0.15s;
  outline: 0;
  stroke: white;
}
.kss-style .modal__header-close:hover, .kss-style .modal__header-close:focus, .kss-style .modal__header-close:active {
  opacity: 1;
}
.kss-style .modal__body {
  padding: 1rem;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.kss-style .modal__body *:last-child {
  margin-bottom: 0;
}
.kss-style .modal__footer {
  padding: 1rem;
}
.kss-style .fade-enter-from,
.kss-style .fade-leave-to {
  opacity: 0;
}
.kss-style .fade-enter-active,
.kss-style .fade-leave-active {
  transition: opacity 0.5s;
}
.kss-style .slide-in-top-enter-from,
.kss-style .slide-in-top-leave-to {
  opacity: 0;
  transform: translateY(-4rem);
}
.kss-style .slide-in-top-enter-active,
.kss-style .slide-in-top-leave-active {
  transition: all 0.5s;
}

.kss-style .search {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #666;
}
.kss-style .search svg {
  fill: inherit;
  stroke: inherit;
}
.kss-style .search-input {
  position: relative;
  z-index: 1;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background: #fff;
  border: 0.125rem solid #fb9937;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
}
.kss-style .search-input:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(251, 153, 55, 0.2);
}
.kss-style .search-input__label {
  width: 1.5rem;
  height: 1.5rem;
  fill: #fb9937;
}
.kss-style .search-input__field {
  flex: 1;
  border: 0;
  outline: 0;
  padding: 0.5rem 0;
  background: transparent;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}
.kss-style .search-input__field::placeholder {
  color: #ccc;
}
.kss-style .search-input__clear {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.5;
  transition: opacity 0.15s;
  stroke: #666;
}
.kss-style .search-input__clear:hover, .kss-style .search-input__clear:focus, .kss-style .search-input__clear:active {
  opacity: 1;
}
.kss-style .search-results {
  overflow: hidden;
}
.kss-style .search-results__title {
  font-size: 1rem;
}
.kss-style .search-results-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style-type: none;
  padding: 0.25rem 0;
  max-height: min(500px, 40vh);
  overflow-x: hidden;
  overflow-y: auto;
}
@media screen and (min-width: 1024px) {
  .kss-style .search-results-list {
    max-height: min(500px, 50vh);
  }
}
.kss-style .search-results-item {
  display: block;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
}
.kss-style .search-results-item:hover, .kss-style .search-results-item:focus-within {
  background: rgba(251, 153, 55, 0.2);
}
.kss-style .search-results-item:focus-within {
  box-shadow: inset 0 0 0 0.25rem rgba(251, 153, 55, 0.5);
}
.kss-style .search-results-item__description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.kss-style .slide-in-left-enter-from,
.kss-style .slide-in-left-leave-to {
  opacity: 0;
  transform: translateX(4rem);
}
.kss-style .slide-in-left-enter-active,
.kss-style .slide-in-left-leave-active {
  transition: all 0.5s;
}
.kss-style .base-list-enter-from,
.kss-style .base-list-leave-to {
  opacity: 0;
  transform: translateY(-2rem);
}
.kss-style .base-list-enter-active,
.kss-style .base-list-leave-active {
  transition: all 0.3s ease;
}
.kss-style .base-list-move {
  transition: transform 0.3s;
}